table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
}

body {
    font-family: Verdana, sans-serif;
    margin-left: 50px;
    margin-right: 50px;
    background-color: #1A2B3C;
    color: #e8e8e8;
    /*background:#e8e9ea  ;*/
    /*background-image: url(https://www.bondspark.co.za/static/media/noisy-texture.fa6642c5.png);*/
    /*background-repeat: repeat;*/
}

button {
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    box-shadow: 2px 2px 5px #555555;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.mt-50 {
    margin-top: 50px;
}


.primary {
    background-color: #FFD700;
    color: #1A2B3C;
    border: none;
}

.primary:hover {
    scale: 1.05;
    border: none;
}

.tableWrapper {
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

input[type="text"] {
    background-color: #F5F5F5;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    box-shadow: 2px 2px 5px #555555;
}

input[type="number"], select {
    background-color: #F5F5F5;
    border: 1px solid #1A2B3C;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    /*box-shadow: 1px 1px 10px #555555;*/
    margin: 5px;
}

input[type="email"] {
    background-color: #F5F5F5;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    box-shadow: 2px 2px 5px #555555;
}

label {
    font-size: 16px;
    margin: 5px;
}

input[type="text"]:focus {
    outline: none;
    box-shadow: 2px 2px 3px #00CC66;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
    margin: 5px;
}

.input-group button {
    margin: 5px;
}

.insights-card {
    background-color: #1F1F1F;
    color: #F5F5F5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 20px;
    flex: 1
}

.insights-card-header {
    background-color: #5472d3;
    color: #F5F5F5;
    font-size: 1.5em;
    padding: 10px;
    border-radius: 10px;
}

.insights-card-body {
    color: #F5F5F5;
    font-size: 1.2em;
    display: flex;
}

.above-the-fold {
    display: flex;
    flex-direction: row;
}

.amortization-wrapper {
    overflow-x: scroll;
    border-collapse: collapse;
    width: 100%;
}


#amortizationTable {
    margin: auto;
    font-size: 14px;
}

.milestones {
    display: flex;
}


.transfer-and-bond-fees {
    display: flex;
}

.card {
    display: flex;
    flex-direction: column;
    background-color: #e8e8e8;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    animation: all 0.5s ease-in-out;
}

.mb-10 {
    margin-bottom: 10px;
}

.m-10 {
    margin: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.m-20 {
    margin: 20px;
}

.mx-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mx-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.d-flex {
    display: flex;
}

.d-align-center {
    align-items: center;
}

.d-justify-center {
    align-items: center;
}

.icon-button {
    height: 35px;
    width: 35px;
    text-align: center;
    padding: 0;
    border-radius: 50%;
    background-color: #5472d3;
    float: right;
    color: #e8e8e8;
    font-size: 20px;
}

.background-black {
    background-color: #1F1F1F;
}

.positive {
    color: #00CC66;
}

.negative {
    color: #FF4136;
}

.section-header {
    margin: 20px 20px 0;
}

.amortization-wrapper {
    overflow-x: scroll;
    border-collapse: collapse;
    width: 100%;
    height: 600px;
}

.tableWrapper {
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#amortizationTable {
    margin: auto;
    /*border-collapse: separate; !* Don't collapse *!*/
    border-spacing: 0;
}

#amortizationTable tbody td.positive {
    background-color: #00CC66;
    color: #fff;
}

#amortizationTable tbody td.negative {
    background-color: #FF4136;
    color: #fff;
}

thead tr:nth-child(1) th {
    background-color: #3F3F3F;
    position: sticky;
    top: -1px;
}

.headliner-profit {
    font-size: 48px;
}

.headliner-description {
    font-size: 20px;
}

.monthly-payments-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.additional-payment-badge-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e8e8e8;
    border-radius: 5px;
    margin: 10px;
}

.use-example-values-container {
    font-size: 20px;
    margin: 10px 0;
    cursor: pointer;
    text-underline: none;
}

.use-example-values-container a:visited {
    color: inherit;
}

.use-example-values-container a {
    text-decoration: none;
    color: #e8e8e8;
}

.mobile-show {
    display: none;
}

.title {
    font-size: 30px;
    font-weight: bold;
}

.title a {
    text-decoration: none;
}

@media only screen and (max-width: 576px) {
    body {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .above-the-fold {
        flex-direction: column;
        align-items: center;
    }

    /*td {*/
    /*    display: none;*/
    /*}*/
    td:nth-child(1),
    td:nth-child(10),
    td:nth-child(11) {
        display: table-cell;
    }

    .milestones {
        flex-direction: column;
        align-items: center;
    }

    .headliner {
        flex-direction: column;
    }

    .headliner-profit {
        font-size: 30px;
    }

    .headliner-description {
        font-size: 16px;
    }

    .insights-card {
        padding: 0;
        margin: 10px 0;
    }

    .insights-card-header {
        /*padding: 0;*/
        /*width: 100%;*/
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .m-10-mobile {
        margin: 10px;
    }

    .section-header {
        margin: 10px 10px 0;
        font-size: 20px;
    }

    .transfer-and-bond-fees {
        display: flex;
        flex-direction: column;
    }

    .main-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .main-form .input-group {
        max-width: initial;
        width: 100%;
    }

    #amortizationTable {
        margin: auto;
        font-size: 13px;
    }

    .use-example-values-container {
        font-size: 16px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .mobile-show {
        display: block;
    }

    .title {
        font-size: 24px;
        font-weight: bold;
    }

    .mobile-hide {
        display: none;
    }
}

.contact-form-initiator {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;

}

.contact-form-wrapper {
    position: relative;
    width: 500px;
}

.no-scroll {
    overflow: hidden;
}


.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0s;
}

.pointer {
    cursor: pointer;
}

.text-dark-blue {
    color: #1A2B3C;
}

.underline {
    text-decoration: underline;
}

.tooltip {
    position: relative;
    cursor: pointer;
    /*border-bottom: 1px dotted black;*/
}
.tooltip span {
    visibility: hidden;
    width: 200px;
    background-color: #F5F5F5;
    color: #1A2B3C;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 9;
    top: -1em;
    left:  100%;
    margin-left:0.5em;
    opacity: 0;
    font-size: 0.8rem;
}
.tooltip span::after {
    content: "";
    position: absolute;
    top: 1.5em;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #F5F5F5 transparent transparent;
}
.tooltip input {
    display:none;
}
.tooltip input:checked+span {
    visibility: visible;
    opacity: 1;
}

@keyframes loader {
    0% {
        top: 8px;
        height: 64px;
    }
    50%, 100% {
        top: 24px;
        height: 32px;
    }
}

.gold-bg {
    background-color: #FFD700;
}

.property-card {
    max-width: 800px;
    width: 100%;
    /*background-color: white;*/
    background: #1F3A5F; /*Denim blue*/
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card-content {
    display: flex;
    /*flex-direction: column;*/
}

.image-container {
    height: auto;
    width: 150px;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    text-align: center;
    justify-content: center;
}

.card-title {
    text-align: center;
    /*font-size: 16px;*/
    align-items: center;
    /*text-transform: uppercase;*/
    letter-spacing: 0.05em;
    font-weight: 600;
    /*color: #1A2B3C;*/
    /*color: #4f46e5;*/

    margin-bottom: 8px;
}

.card-description {
    /*font-size: 16px;*/
    /*color: #4b5563;*/
    margin: 0;
    text-align: center;
}

.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
    .card-content {
        flex-direction: row;
    }

    .image-container {
        width: 300px;
        height: auto;
    }

    .text-container {
        flex: 1;
        align-items: center;
        justify-content: center;
    }
}

