.monthly-summary-card {
    background-color: #1F1F1F;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 20px;
    max-width: 300px;
}

.monthly-summary-card h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #F5F5F5;
}

.monthly-summary-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    color: #e8e8e8;
}


.monthly-summary-info .additional-info {
    display: flex;
    flex-direction: column;
}

.monthly-summary-info div {
    line-height: 1.4;
}

.cash-flow .amount {
    font-weight: bold;
    font-size: 16px;
}

.cash-flow {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 14px;
}


@media only screen and (max-width: 576px) {
    .monthly-summary-card {
        margin: 5px;
        width:100%;
    }
}

.toggle-expand {
    font-size: 20px;
    display: flex;
    background: #5472d3;
    color: #F5F5F5;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 10px;
    align-self: center;
}

